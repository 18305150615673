import React from 'react'
import Site from '../components/Layout/Site'
import DefaultVector from '../components/Shared/DefaultVector'
import { Col, Container, Row } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import { Heading } from '../components/UI/Typography'
import styled from 'styled-components'

const TestCapability = () => {
  return (
    <Site>
      <Section defaultVector py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container pb={10}>
            <Heading size="h2" mb={10} textAlign="center">
              Test Capability
            </Heading>
            <Row justifyContent="space-between">
              <Col col={{ lg: 5 }} mb={6}>
                <Heading size="h6">Test Hardware/Load Board Development</Heading>
                <ul>
                  <li>Design and generate schematic</li>
                  <li>Reverse Engineering</li>
                  <li>Load board construction (hand wire type)</li>
                </ul>
              </Col>
              <Col col={{ lg: 5 }} mb={{ _: 6, lg: 10 }}>
                <Heading size="h6">Test Software Development</Heading>
                <ul>
                  <li>Develop test program from scratch based on datasheet</li>
                  <li>Develop Test Solution and create test plan</li>
                  <li>Test Program Migration or conversion</li>
                </ul>
              </Col>
              <Col col={{ lg: 5 }} mb={{ _: 6 }}>
                <Heading size="h6">
                  Qualification & correlation of new test program and load board
                </Heading>
                <ul>
                  <li>Design and generate schematic</li>
                  <li>Reverse Engineering</li>
                  <li>Load board construction (hand wire type)</li>
                </ul>
              </Col>
              <Col col={{ lg: 5 }} mb={{ _: 6, lg: 10 }}>
                <Heading size="h6">Experience in the following product types</Heading>
                <ul>
                  <li>Analog Switches, Video Amplifier, Step Down Converter</li>
                  <li>Voltage Regulator/Reference (FT and Wafer Probing), Switching Regulator</li>
                  <li>HV Current Sensing Single Driver, MOSFET Driver</li>
                  <li>
                    Low Power Thermostat, Linear Battery Charger Controller, Temperature Sensor
                  </li>
                  <li>Discrete (FET, Diode, Thyristor)</li>
                </ul>
              </Col>
            </Row>

            <Heading size="h2" textAlign="center" mb={5}>
              Electrical Test Capability - Discrete Device
            </Heading>

            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th colspan="2">Standard DC Test</th>
                  <th colspan="2">DC High Voltage Test</th>
                  <th colspan="2">DC High Current Test</th>
                </tr>
                <tr>
                  <th>Device Type</th>
                  <th>Measurement Range</th>
                  <th>Forcing Range</th>
                  <th>Measurement Range</th>
                  <th>Forcing Range</th>
                  <th>Measurement Range</th>
                  <th>Forcing Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>FET</td>
                  <td>20A / 1000V</td>
                  <td>20A / 1000V</td>
                  <td>10mA / 3KV</td>
                  <td>10mA / 3KV</td>
                  <td>10A / 20V</td>
                  <td>200A / 25V</td>
                </tr>
                <tr>
                  <td>Transistor</td>
                  <td>20A / 1000V</td>
                  <td>20A / 1000V</td>
                  <td>10mA / 3KV</td>
                  <td>10mA / 3KV</td>
                  <td>20V</td>
                  <td>200A / 25V</td>
                </tr>
                <tr>
                  <td>Thyristor</td>
                  <td>200mA / 1000V</td>
                  <td>20A / 1000V</td>
                  <td>10mA / 3KV</td>
                  <td>10mA / 3KV</td>
                  <td>20V</td>
                  <td>200A</td>
                </tr>
                <tr>
                  <td>Opto-coupler</td>
                  <td>200mA / 1000V</td>
                  <td>20A / 1000V</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>Voltage Regulator</td>
                  <td>20A / 20V</td>
                  <td>20A / 80V</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>Diode</td>
                  <td>10mA / 1000V</td>
                  <td>20A / 1000V</td>
                  <td>10mA / 3KV</td>
                  <td>10mA / 3KV</td>
                  <td>20V</td>
                  <td>200A</td>
                </tr>
              </tbody>
            </Table>

            <Heading size="h2" textAlign="center" mb={5} mt={10}>
              Electrical Test Capability
            </Heading>
            <Table>
              <thead>
                <tr>
                  <th colspan="4">HIPOT / Withstanding Voltage Testing</th>
                </tr>
                <tr>
                  <th colspan="2">ACW Voltage Test</th>
                  <th colspan="2">DCW Voltage Test</th>
                </tr>
                <tr>
                  <th>Forcing Range</th>
                  <th>Measurement Range</th>
                  <th>Forcing Range</th>
                  <th>Measurement Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0.0kV - 5.5kV</td>
                  <td>0.01mA - 110mA</td>
                  <td>0.0kV - 6.2kV</td>
                  <td>0.01mA - 110mA</td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th colspan="3">Switching Test</th>
                </tr>
                <tr>
                  <th colspan="3">Unclamped Inductive Switching (UIS Test)</th>
                </tr>
                <tr>
                  <th>Current Range</th>
                  <th>Voltage Range</th>
                  <th>Load Inductance Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DCW Voltage Test</td>
                  <td>0.0kV - 6.2kV</td>
                  <td>0.01mA - 110mA</td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th colspan="3">TVS Clamping Voltage Testing</th>
                </tr>
                <tr>
                  <th>Pulses</th>
                  <th>Forcing range for the lpp</th>
                  <th>Measurement range for the Clamping Voltage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    10x1000uS
                    <br />
                    6x7uS
                    <br />
                    8x20uS
                  </td>
                  <td>0.0kV - 6.2kV</td>
                  <td>0.01mA - 110mA</td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th colspan="6">
                    Thermal Resistance Test For Transistors, Diodes, MOSFETs and IGBTs
                  </th>
                </tr>
                <tr>
                  <th colspan="6">ΔVF / ΔVCE / ΔVBE / ΔVSD/ ΔVGS</th>
                </tr>
                <tr>
                  <th>Forcing Voltage</th>
                  <th>Forcing Current</th>
                  <th>Sensing Current</th>
                  <th>Power dissipation time</th>
                  <th>Delay Time</th>
                  <th>Measurement range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 – 200V</td>
                  <td>0.001 – 50A</td>
                  <td>
                    1 to 100mA
                    <br />
                    (1 to 400mA for ΔVCE)
                  </td>
                  <td>300uS to 9.99s</td>
                  <td>10 to 999uS</td>
                  <td>0 – 999mV</td>
                </tr>
              </tbody>
            </Table>

            <Heading size="h2" textAlign="center" mb={5} mt={10}>
              Wafer Sort Capability
            </Heading>
            <Table>
              <thead>
                <tr>
                  <th colspan="4">Handlers</th>
                </tr>
                <tr>
                  <th>Manufacturer & Model</th>
                  <th>Package Type</th>
                  <th>Contact Sites</th>
                  <th>Temperature range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>EG4080</td>
                  <td>4-8” wafers, MLP; DFN; QFN; CSP</td>
                  <td>Multi</td>
                  <td>Room temp</td>
                </tr>
                <tr>
                  <td>EG4200</td>
                  <td>4-8” wafers, MLP; DFN; QFN; CSP</td>
                  <td>Multi</td>
                  <td>Room, hot and cold temp</td>
                </tr>
              </tbody>
            </Table>

            <Heading size="h2" textAlign="center" mb={5} mt={10}>
              Final Test Package Capability
            </Heading>
            <Table>
              <thead>
                <tr>
                  <th colspan="4">Test Handlers</th>
                </tr>
                <tr>
                  <th>Manufacturer & Model</th>
                  <th>Package Type</th>
                  <th>Contact Sites</th>
                  <th>Temperature range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MCT 5100</td>
                  <td>MSOP/SOIC/TSSOP/DFN</td>
                  <td>Dual Site</td>
                  <td>Room, hot temp</td>
                </tr>
                <tr>
                  <td>RASCO SO1000T</td>
                  <td>MSOP/4X4 / 3X3 / 4X6</td>
                  <td>Quad Site</td>
                  <td>Room, hot and cold temp</td>
                </tr>
                <tr>
                  <td>RASCO SO2000T</td>
                  <td>QSOP/SOIC</td>
                  <td>Quad Site</td>
                  <td>Room, hot and cold temp</td>
                </tr>
              </tbody>
            </Table>

            <Table>
              <thead>
                <tr>
                  <th colspan="4">Test Handlers</th>
                </tr>
                <tr>
                  <th>Manufacturer & Model</th>
                  <th>Package Type</th>
                  <th>Contact Sites</th>
                  <th>Temperature range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TESEC9512 (In-line Marking)</td>
                  <td>
                    TO-220 2L/3L
                    <br />
                    TO-247 2L/3L/4L
                  </td>
                  <td>6 Sites index-parallel</td>
                  <td>Room temp</td>
                </tr>
                <tr>
                  <td>TESEC9718 (In-line marking and Tape and reel)</td>
                  <td>SOT-223 / SOT23 3L</td>
                  <td>Single</td>
                  <td>Room temp</td>
                </tr>
                <tr>
                  <td>TAESUK THD-111</td>
                  <td>TO-92</td>
                  <td>Single</td>
                  <td>Room temp</td>
                </tr>
                <tr>
                  <td>SHINJA JT100 / SHINJA ST400 (T&r)</td>
                  <td>SOT-89</td>
                  <td>Single</td>
                  <td>Room temp</td>
                </tr>
              </tbody>
            </Table>

            <Heading size="h2" textAlign="center" mb={5} mt={20}>
              Test/Added Value Services
            </Heading>
            <Row justifyContent="space-between">
              <Col col={{ lg: 5 }} mb={6}>
                <ul>
                  <li>New Product Qualification</li>
                  <li>Test Yield Improvement</li>
                  <li>Test Datalog and Statistical Analysis</li>
                </ul>
              </Col>
              <Col col={{ lg: 5 }} mb={{ _: 6, lg: 10 }}>
                <ul>
                  <li>24-hour Failure Analysis</li>
                  <li>
                    We can expand our test capability on other analog/linear and mixed signal
                    devices
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;

  & + & {
    margin-top: 24px;
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid #9e9e9e;
        padding: 6px 12px;
        font-weight: 600;
      }

      &:first-child {
        th {
          background: #3342a4;
          color: #fff;
          border-left: 1px solid #3342a4;
          font-size: 20px;

          &:last-child {
            border-right: 1px solid #3342a4;
          }
        }
      }

      &:nth-child(2) {
        th {
          background: #5467ce;
          color: #fff;
          border-left: 1px solid #5467ce;

          &:last-child {
            border-right: 1px solid #5467ce;
          }
        }
      }

      &:nth-child(3) {
        th {
          background: #fff;
          color: var(--text);
          border-left: 1px solid #9e9e9e;

          &:last-child {
            border-right: 1px solid #9e9e9e;
          }
        }
      }
    }
  }

  tbody {
    td {
      border-bottom: 1px solid #9e9e9e;
      border-left: 1px solid #9e9e9e;
      padding: 6px 12px;

      &:last-child {
        border-right: 1px solid #9e9e9e;
      }
    }
  }
`

export default TestCapability
